import React, { Component } from 'react';
import Parallox from 'components/parallox';

import './TransitionImage.scss';


class TransitionImage extends Component {

    render() {
        return (
            <div className="TransitionImage">
                <div className="overlay"/>
                <Parallox id={this.props.id} image={this.props.image}/>
            </div>
        );
    }
}

export default TransitionImage;
