import React, { Component} from 'react';
import Main from './main';
import Services from './services';
import Values from './values';
import People from './people';
import ContactForm from './contact-form';
import TransitionImage from './TransitionImage';

import './Slides.scss';

class Slides extends Component {

    render() {
        return (
            <div className="Slides">
                <Main id="main-slide"/>
                <Services id="services-slide"/>
                <Values id="values-slide"/>
                <TransitionImage image={require("./people/people.webp")}/>
                <People id="people-slide"/>
                <ContactForm id="contact-form-slide"/>
            </div>
        );
    }
}

export default Slides;
