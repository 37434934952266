import React, { Component } from 'react';
import Scrollchor from 'react-scrollchor';
import Scrollspy from 'react-scrollspy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import esca from './ESCA.webp';

import './Navbar.scss'

class Navbar extends Component {

    constructor() {
        super();
        const menu = require('./menu.json');

        const items = menu.reduce((acc, curr) => {
            acc.push(curr.anchor);
            return acc;
        }, []);
        this.state = {
            showMenu: false,
            menu,
            items
        };
    }

    showMenu() {
        this.setState({
            showMenu: true
        });
    }

    closeMenu() {
        this.setState({
            showMenu: false
        });
    }

    render() {
        return (
            <div className={`Navbar ${this.state.showMenu ? 'show-menu' : ''}`}>
                <a className="logo" href="/">
                    <img src={esca} alt="logo"></img>
                </a>
                <Scrollchor className="title" to="#root" disableHistory>Abogados & Consultores</Scrollchor>
                <div className="menu-mobile">
                    <span className="bars" onClick={(e) => this.showMenu()}>
                        <FontAwesomeIcon icon="bars" color="rgba(0, 48, 91, 1)"/>
                    </span>
                    <span className="times" onClick={(e) => this.closeMenu()}>
                        <FontAwesomeIcon icon="times" color="rgba(0, 48, 91, 1)"/>
                    </span>
                </div>
                <Scrollspy className="menu" items={this.state.items} currentTag="nav" currentClassName="active">
                    {this.state.menu.map((menuItem, i) =>
                        <div key={i} className="menu-item" onClick={(e) => this.closeMenu()} style={{
                            visibility: menuItem.visible === false ? 'hidden' : 'inherit',
                            width: menuItem.visible === false ? '1px' : 'inherit',
                        }}>
                            <Scrollchor to={menuItem.anchor} disableHistory>{menuItem.name}</Scrollchor>
                        </div>
                    )}
                </Scrollspy>
            </div>
        );
    }
}

export default Navbar;
