import React, { Component} from 'react';
import Slide from '../Slide';

import './Values.scss';

class Values extends Component {

    render() {
        return (
            <Slide id={this.props.id} className="Values">
                <div className="our-signature container" data-aos="fade-right">
                    <div className="title">
                        Nuestra firma
                    </div>
                    <div className="content">
                        <p>
                            Esca Abogados y Consultores, formado por un equipo de
                            profesionales en las áreas de derecho, economía y
                            logística, en especial en Transporte de mercancías por
                            carretera,ofrece a sus  clientes la garantía de tener 
                            una experiencia de mas de 20 años en el sector y ser una
                            asesoría con dinámica de servicio nueva donde la persona
                            es lo primero para la firma.
                        </p>
                    </div>
                </div>
                <div className="our-values container" data-aos="fade-left">
                    <div className="title">
                        Nuestros valores
                    </div>
                    <div className="content">
                        <p>
                            Desarrollamos nuestro trabajo de acuerdo con los 
                            siguientes valores:
                        </p>
                        <p>
                            <b>Calidad:</b> Nuestro principal objetivo es
                            proporcionar un servicio de calidad, basándonos
                            siempre en la rigurosa técnica, la flexibilidad y la
                            integridad, para poder superar las expectativas de
                            nuestros clientes.
                        </p>
                        <p>
                            <b>Honestidad:</b> No solo cumplimos nuestro código
                            deontológico, sino que entendemos que las personas
                            están por encima del negocio, asesorando a nuestros
                            clientes no solo desde una perspectiva profesional
                            sino humana. 
                        </p>
                        <p>
                            <b>Profesionalidad:</b> Somos profesionales con una
                            dilatada experiencia y continua formación, con un
                            alto grado de compromiso con nuestro trabajo, lo
                            cual es garantía de calidad en cada uno de nuestros
                            servicios.
                        </p>
                        <p>
                            <b>Relación duradera:</b> con nuestras igualas a
                            empresas pretendemos construir una relación estable
                            y duradera lo que implica un mayor esfuerzo y
                            compromiso en todos nuestros servicios, aportándole
                            en cada momento una solución precisa.
                        </p>
                    </div>
                </div>
            ​</Slide>
        );
    }
}

export default Values;
