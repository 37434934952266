
class ContactService {
    
    async getContacts() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/team`);
        const contacts = await response.json();

        return contacts;
    }

}


export default new ContactService();
