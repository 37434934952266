import React, { Component } from 'react'; 
import Slide from '../Slide';


import video from './video.mp4';
import './Main.scss';

class Main extends Component {

    playVideo(video) {
        video.play();
    }

    render() {
        return (
            <Slide id={this.props.id} className="Main">
                <div className="front">
                    <div className="overlay">
                        <span className="title">
                            ESCA ABOGADOS & CONSULTORES
                        </span>
                        <span className="subtitle">
                            Servicios Juridicos <br/>
                            Servicios de Gestoria y Asesoria de Empresas
                        </span>
                    </div>
                </div>
                <div className="background">
                    <video playsInline crossOrigin="anonymous" autoPlay
                        muted className="bgVideovideo"
                        onEnded={(e) => this.playVideo(e.target)}
                        src={video}></video>
                    <div className="video-alt"></div>
                </div>
            </Slide>
        );
    }
}

export default Main;
