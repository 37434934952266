import React, { Component } from 'react';
import Slide from '../Slide';
import Contact from './Contact';

import ContactService from './ContactService';
import './People.scss';


class People extends Component {
    constructor() {
        super();
        this.state = {
            contacts: []
        }
        this.getContacts()
    }

    async getContacts() {
        const contacts = await ContactService.getContacts();
        console.log(contacts);
        this.setState({ contacts });
    }

    render() {
        return (
            <Slide id={this.props.id} className="People">
                <div className="title" data-aos="zoom-in">Equipo humano</div>
                <div className="human-resources">
                    {this.state.contacts.map((contact, i) =>
                        <Contact key={i} {...contact}/>
                    )}
                </div>
            </Slide>
        );
    }
}

export default People;
