import React from 'react';
import ReactDOM from 'react-dom';
import Plugins from './plugins';
import * as serviceWorker from './serviceWorker';
import App from './app';

import './index.scss';


(function () {
    Plugins.init();

    ReactDOM.render(<App />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();  
}());
