import React, { Component} from 'react';

import './Slide.scss';

class Slide extends Component {

    render() {
        return (
            <div className={`Slide ${this.props.className}`}>
                <div id={this.props.id}  className="slide-anchor"></div>
                {this.props.children}
            </div>
        );
    }
}

export default Slide;
