import React, { Component} from 'react';

import './ServiceBox.scss';

class ServiceBox extends Component {
    onClick(e) {
        e.preventDefault();
        if(this.props.onClick){
            this.props.onClick(e);
        }
    }

    render() {
        return (
            <div className="ServiceBox" data-aos="zoom-in">
                <span className="service-dot"></span>
                <div className="service">
                    <div className="service-name">{this.props.service}</div>
                    <div className="service-summary">
                        {this.props.children}... <a href="#" onClick={(e) => this.onClick(e)}>más</a>
                    </div>
                    <div className="service-image">
                        <img src={this.props.image} alt='service'/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceBox;
