import React, { Component } from 'react';
import Slide from '../Slide';
import Parallox from 'components/parallox';
import PMap from 'components/p-map';

import background from './background.webp';
import './ContactForm.scss';


class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = { ...this.emptyEmail() };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    emptyEmail() {
        return {
            name: "",
            email: "",
            subject: "",
            message: ""
        };
    }

    valueGetter = (inputName) => {
        return this.state[inputName];
    }

    handleChange = (inputName) => (event) => {
        this.setState({
            [inputName]: event.target.value
        });
    }

    async handleSubmit(event) {
        console.log('A name was submitted: ', this.state);
        event.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/question`, {
            method: 'POST',
            body: JSON.stringify(this.state), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 200) {
            alert("Mensaje enviado!");
            this.setState(this.emptyEmail());
        }
    }

    formInput = (props) => {
        const { inputName, ...divProps } = props;
        return <input {...divProps} value={this.valueGetter(inputName)} onChange={this.handleChange(inputName)}/>
    }

    formTextArea = (props) => {
        const { inputName, ...divProps } = props;
        return <textarea {...divProps} value={this.valueGetter(inputName)} onChange={this.handleChange(inputName)}/>
    }

    render() {
        const mapAddress = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2994.727621664298!2d2.1386842504098857!3d41.35827370566174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd35a29c8d31db886!2sEs%26Ca+Abogados+Consultores!5e0!3m2!1ses!2ses!4v1543259935567";
        return (
            <Slide id={this.props.id} className="ContactForm">
                <div className="title-container">
                    <div>
                        <div className="title" data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">Contactanos</div>
                        <div className="subtitle" data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">ESCA ABOGADOS & CONSULTORES</div>
                    </div>
                </div>
                <div className="form-container">
                    <div className="form-group">
                        <div className="form-title">
                            <span className="highlight">Nuestra</span> dirección
                        </div>
                        <div className="form-content">
                            <PMap src={mapAddress}>
                                Paseo de la Zona Franca 134 ent 1ª. 08038, Barcelona. (Click para abrir el mapa)
                            </PMap>
                            <p>
                                <span className="highlight">Email: </span>
                                <a href="mailto:escaabogados@escaabogados.es">escaabogados@escaabogados.es</a>
                            </p>
                            <p><span className="highlight">Tel:</span> 93 249 23 14</p>
                        </div>
                    </div>
                    <div className="contact-form">
                        <p>Para preguntas generales, por favor completa el siguiente formulario:</p>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-inputs">
                                <div className="form-column">
                                    <this.formInput type="text"  placeholder="Name *"    inputName="name"    required/>
                                    <this.formInput type="email" placeholder="Email *"   inputName="email"   required/>
                                    <this.formInput type="text"  placeholder="Subject"   inputName="subject"/>
                                </div>
                                <div className="form-column">
                                    <this.formTextArea placeholder="Message" inputName="message" required/>
                                </div>
                            </div>
                            <div className="form-buttons">
                                <input className="button" type="submit" value="Enviar" />
                            </div>
                        </form>
                    </div>
                </div>
                <Parallox className="contact-form-parallox" image={background}/>
            </Slide>
        );
    }
}

export default ContactForm;
