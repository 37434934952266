import React, { Component } from 'react';
import Navbar from './navbar';
import Slides from './slides';
import ScrollUp from './scroll-up';
import Footer from './footer';

import './App.scss';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar/>
        <Slides/>
        <ScrollUp/>
        <Footer/>
      </div>
    );
  }
}

export default App;
