import React, { Component } from 'react';
import ServiceBox from './ServiceBox';
import Slide from '../Slide';
import imgFiscal from './images/Fiscal.jpg';
import imgJuridicos from './images/Juridicos.jpg';
import imgLaboral from './images/Laboral.jpg';
import imgTransporte from './images/Transporte.jpg';
import imgConsultoria from './images/Consultoria.jpg';


import './Services.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const images = {
  'fiscalContable': imgFiscal,
  'serviciosJuridicos': imgJuridicos,
  'areaLaboral': imgLaboral,
  'transporte': imgTransporte,
  'consultoria': imgConsultoria
}

class Services extends Component {
  state = {
    view: "default",
    prevView: "default",
    views: {
      default: "show"
    },
    contentHeight: 0
  };
  divContent;

  getViewClass(view) {
    return `${view} service ${this.state.views[view] || ""}`
  }

  goView(view) {
    const prevView = this.state.view;
    const views = {
      [prevView]: "hide",
      [view]: "show"
    };
    const activeContent = this.divContent.querySelector(`.${view}`);
    const contentHeight = this.getContentHeight(activeContent);

    this.setState({ view, views, prevView, contentHeight });

    // Scroll to content
    const top = this.divContent.parentElement.offsetTop; //Getting Y of target element
    const navbarHeight = 80;
    window.scrollTo(0, top - navbarHeight);             //Go there directly or some transition
  }

  goBack() {
    this.goView("default");
  }

  componentDidMount() {
    setTimeout(() => {
      const activeContent = this.divContent.querySelector('.show');
      this.refreshContentHeight(activeContent);
    }, 300);

    setTimeout(() => {
      const activeContent = this.divContent.querySelector('.show');
      this.refreshContentHeight(activeContent);
    }, 1000);

    window.addEventListener('resize', (e) => {
      const activeContent = this.divContent.querySelector('.show');
      this.refreshContentHeight(activeContent);
    });
  }

  setContentRef = (ref) => {
    this.divContent = ref;
  };

  refreshContentHeight = (element) => {
    const contentHeight = this.getContentHeight(element);

    this.setState({ contentHeight });
  }

  getMinHeight() {
    return this.divContent.querySelector('.default').getBoundingClientRect().height;
  }

  getContentHeight(element) {
    const contentChildHeight = element.getBoundingClientRect().height;
    const minHeight = this.getMinHeight();

    const contentHeight = minHeight > contentChildHeight ? contentChildHeight : minHeight;

    return contentHeight;
  }

  getImageInfo() {
    return {
      className: this.state.view !== 'default' ? 'show' : '',
      src: images[this.state.view] || images[this.state.prevView]
    }
  }

  FiscalContable = () => (
    <div className={`${this.getViewClass("fiscalContable")}`}>
      <div className='header' data-aos="zoom-in">
        <button onClick={() => this.goBack()}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <h1>Fiscal - Contable</h1>
      </div>
      <div className='body' data-aos="zoom-in">
        <p>
          Para que la contabilidad sea una herramienta útil para la toma de
					decisiones, prestamos los siguientes servicios en el área contable:
				</p>
        <ul>
          <li>
            Contabilidad obligatoria según El Plan General de Contabilidad
						(Libro Diario, Inventario y Cuentas Anuales).
					</li>
          <li>
            Libro registro de facturas emitidas y ventas.
					</li>
          <li>
            Libro registro de facturas recibidas y gastos.
					</li>
          <li>
            Libro registro de bienes de inversión y control de amortizaciones.
					</li>
          <li>
            Elaboración y depósito de Cuentas Anuales en el Registro Mercantil.
					</li>
          <li>
            Certificación de Aprobación de Cuentas Anuales por Junta Universal
						de socios.
					</li>
          <li>
            Legalización de libros oficiales en el Registro Mercantil.
					</li>
          <li>
            Informes de gestión trimestrales, donde podrá valorar la marcha de
						su empresa en el ejercicio actual y en comparación con los
						anteriores. Listados completos de las facturas emitidas, recibidas,
						acumulados por clientes y proveedores, costes laborales, cuentas de
						resultados con desglose mensual, etc.
					</li>
        </ul>
      </div>
    </div>
  );

  Juridicos = () => (
    <div className={`${this.getViewClass("serviciosJuridicos")}`}>
      <div className='header' data-aos="zoom-in">
        <button onClick={() => this.goBack()}>
          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
        </button>
        <h1>Servicios Juridicos</h1>
      </div>
      <div className='body' data-aos="zoom-in">
        <h2>Derecho Laboral</h2>
        <p>
          Nuestra dilatada experiencia nos permite dar cobertura tanto en el
					sector empresarial como particular
				</p>
        <ul>
          <li>Reajustes de plantillas</li>
          <li>Despidos Objetivos</li>
          <li>Eres</li>
          <li>Análisis de Convenios Colectivos</li>
          <li>Estudio de la estructura salarial (según convenios)</li>
          <li>Reclamaciones de cantidades</li>
          <li>Despidos</li>
          <li>Acosos Laborales</li>
          <li>Análisis de indemnizaciones y finiquitos</li>
          <li>Asesoramiento Jurídico</li>
        </ul>
      </div>
      <div className='body' data-aos="zoom-in">
        <h2>Derecho Civil</h2>
        <p>
          Damos soluciones, pensando en lo mejor para la persona y la familia,
					sin perder de vista los bienes patrimoniales
				</p>
        <ul>
          <li>Separaciones Matrimoniales</li>
          <li>Trámites de Divorcio</li>
          <li>Herencias</li>
          <li>Accidentes de Tráfico</li>
          <li>Consultas Jurídicas</li>
        </ul>
      </div>
      <div className='body' data-aos="zoom-in">
        <h2>Derecho Mercantil</h2>
        <p>
          La sociedad mercantil es la herramienta de trabajo de nuestros
					clientes
				</p>
        <ul>
          <li>Constitución de Sociedades Mercantiles</li>
          <li>Negociación de pactos con accionistas</li>
          <li>Transformaciones de sociedades mercantiles</li>
          <li>Operaciones societarias en general ( ampliación y disminución de
						capital, modificaciones estatutarias)</li>
          <li>Asesoramiento a Consejos e Administración, llevanza de
						Secretarías del Consejo</li>
          <li>Preparación de Juntas Generales de Accionistas</li>
          <li>Proceso de liquidaciones de sociedades</li>
          <li>Consultas Jurídicas</li>
        </ul>
      </div>
    </div>
  );

  Laboral = () => (
    <div className={`${this.getViewClass("areaLaboral")}`}>
      <div className='header' data-aos="zoom-in">
        <button onClick={() => this.goBack()}>
          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
        </button>
        <h1>Area Laboral</h1>
      </div>
      <div className='body' data-aos="zoom-in">
        <h2>
          Disponemos de una minuta fija que le da cobertura ante cualquier 
          variación de personal que tenga su empresa sin variaciones en la 
          cuota mensual.
        </h2>
        <p>
          Minuta incluye:
        </p>
        <p>
          Análisis de las nóminas de los trabajadores, las jornadas de trabajo
          realizadas, el convenio vigente y los posibles problemas que puedan
          surgir por la situación actual ante:
        </p>
        <ul>
          <li>Reclamaciones de los trabajadores</li>
          <li>Reclamaciones de la inspección de trabajo</li>
        </ul>
      </div>
      <div className='body' data-aos="zoom-in">
        <p>
          Dentro de la minuta fija se incluye:
        </p>
        <ul>
          <li>Asesoramiento en contratación de personal</li>
          <li>
            Confección de contratos de trabajo, prórrogas y gestion ante el 
            SERVEF
          </li>
          <li>Gestión de subvenciones a la contratación</li>
          <li>Confección de nóminas, finiquitos y seguros sociales</li>
          <li>
            Informe menusal de costes de empresa por centros de trabajo y 
            global de la empresa
          </li>
          <li>
            Elaboración de modelos de retenciones a cuenta sobre el trabajo y 
            su gestión en la Agencia Tributaria
          </li>
          <li>
            Gestiones de parte de accidentes y enfermedad de trabajadores ante 
            la Mutua e INSS
          </li>
          <li>
            Entrega cada año del Calendario Laboral con fiestas de nacionales, 
            autonómicas y locales
          </li>
          <li>Comunicaciones de finalización de contrato</li>
          <li>
            Solicitud de Plan de Prevención de Riesgos Laborales a través de 
            las Mutuas de Accidentes de Trabajo
          </li>
        </ul>
      </div>
    </div>
  );

  Transporte = () => (
    <div className={`${this.getViewClass("transporte")}`}>
      <div className='header' data-aos="zoom-in">
        <button onClick={() => this.goBack()}>
          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
        </button>
        <h1>Gestión de transporte</h1>
      </div>
      <div className='body' data-aos="zoom-in">
        <p>
          25 años en el sector del transporte nos permite garantizar a nuestros
          clientes un servicio integral de calidad que pocos despachos pueden 
          obtener.
        </p>
        <ul>
          <li>Actuaciones ante la inspección de trabajo </li>
          <li>Análisis de los discos Tacógrafos</li>
          <li>Horas de presencia</li>
          <li>Horas estructurales</li>
          <li>Convenios y estructura de nominas</li>
          <li>Asesoramiento normativo del transporte</li>
          <li>Contratos autónomos dependientes (TRADES)</li>
          <li>Extinción contratos autónomos</li>
          <li>Consultas Jurídicas</li>
        </ul>
      </div>
    </div>
  );

  Consultoria = () => (
    <div className={`${this.getViewClass("consultoria")}`}>
      <div className='header' data-aos="zoom-in">
        <button onClick={() => this.goBack()}>
          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
        </button>
        <h1>Consultoría Logística</h1>
      </div>
      <div className='body' data-aos="zoom-in">
        <p>
          Todos nuestros servicios están avalados por la experiencia de 
          personal cualificado y dedicados a mejorar en lo posible si 
          logística:
        </p>
        <ul>
          <li>INFORMES DAFO para ayudar a sus procesos</li>
          <li>
            Revisamos su coste logístico y analizamos posibles mejoras, 
            partiendo del punto 0
          </li>
          <li>Optimizamos su coste y rentabilidad</li>
          <li>Ayudamos a sus proveedores</li>
          <li>Ponemos a su servicio nuestra experiencia </li>
        </ul>
      </div>
    </div>
  );

  render() {
    const { FiscalContable, Juridicos, Laboral, Transporte, Consultoria } = this;
    const { contentHeight } = this.state;
    const imageInfo = this.getImageInfo();

    return (
      <Slide id={this.props.id} className="Services">
        <div className="title-container">
          <div>
            <div className="title" data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">Nuestros</div>
            <div className="subtitle" data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">Servicios</div>
            <img alt='selected-service' {...imageInfo} />
          </div>
        </div>
        <div className="content" ref={(this.setContentRef)} style={{height: `${contentHeight}px`}}>
          <div className={`${this.getViewClass("default")}`}>
            <ServiceBox service={"Fiscal - Contable"}
              onClick={() => this.goView("fiscalContable")}
              image={imgFiscal}>
              Todos nuestros servicios de contabilidad y asesoría
							contable están completamente personalizados
                        </ServiceBox>
            <ServiceBox service={"Servicios jurídicos"}
              onClick={() => this.goView("serviciosJuridicos")}
              image={imgJuridicos}>
              Nuestro departamento jurídico analizará su situación
							personal y le aconsejara
                        </ServiceBox>
            <ServiceBox service={"Área laboral"}
              onClick={() => this.goView("areaLaboral")}
              image={imgLaboral}>
              Nuestra dilatada experiencia nos permite dar cobertura
							tanto en el sector
                        </ServiceBox>
            <ServiceBox service={"Gestión de transporte"}
              onClick={() => this.goView("transporte")}
              image={imgTransporte}>
              25 Años en el sector nos permite garantizar a nuestros
							clientes
                        </ServiceBox>
            <ServiceBox service={"Consultoría logística"}
              onClick={() => this.goView("consultoria")}
              image={imgConsultoria}>
              Abogados y Economistas se preocuparan de analizar la
							optimización de su logística
                        </ServiceBox>
          </div>

          <FiscalContable />
          <Juridicos />
          <Laboral />
          <Transporte />
          <Consultoria />

        </div>
      </Slide>
    );
  }
}

export default Services;
