import React, { Component } from 'react';

import './Parallox.scss';


class Parallox extends Component {

    render() {
        const containerStyle = {
            height: this.props.height
        };
        const imageStyle = {
            backgroundImage: `url(${this.props.image})`
        };

        return (
            <div id={this.props.id} className={`Parallox ${this.props.className || ""}`} style={containerStyle}>
                <div className="background">
                    <div className="background-image" style={imageStyle}></div>
                </div>
            </div>
        );
    }
}

export default Parallox;
