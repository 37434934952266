import React, { Component } from 'react';

import './Contact.scss';


class Contact extends Component {

    render() {
        return (
            <div className="Contact" data-aos="zoom-in">
                <div className="profile-image">
                    <img className="image" src={`data:${this.props.image.type};base64, ${this.props.image.data}`} 
                        alt="Profile image" />
                </div>
                <div className="box-info contact-info">
                    <span className="name">{this.props.name} {this.props.surname}</span>
                    <span className="charge">{this.props.charge}</span>
                </div>
                <span className="splitter"/>
                <div className="box-info extra-info">
                    <span className="phone">Tel: {this.props.phone}</span>
                    <a className="email" href={`mailto:${this.props.email}`}>{this.props.email}</a>
                </div>
            </div>
        );
    }
}

export default Contact;
