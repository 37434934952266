import React, { Component} from 'react';

import './Footer.scss';

class Footer extends Component {

    render() {
        return (
            <div className="Footer">
                <span className="company">
                    2018 Esca Abogados & Consultores,S.L.
                </span>            
            </div>
        );
    }
}

export default Footer;
