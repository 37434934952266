import React, { Component } from 'react';
import Scrollchor from 'react-scrollchor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ScrollUp.scss';


class ScrollUp extends Component {
  render() {
    return (
        <div className="ScrollUp">
            <Scrollchor className="scroll-up-button" to="#root" disableHistory>
                <FontAwesomeIcon icon="chevron-up" color="white" />
                <span>Volver arriba</span>
            </Scrollchor>
        </div>
    );
  }
}

export default ScrollUp;
