import React, { Component } from 'react';
import Popover from 'react-awesome-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PMap.scss';


class PMap extends Component {

    onLoaded(e) {
        console.log(e);
    }

    render() {
        return (
            <div className="PMap">
                <Popover action="click" placement="top">
                    <p className="paragraph">
                        <FontAwesomeIcon className="map-icon" icon="map-marked-alt" style={{display: "block"}}/>
                        {this.props.children}
                    </p>
                    <div className="popover">
                        <iframe title="esca-map" src={this.props.src} 
                                width="600" height="450" frameBorder="0"
                                allowFullScreen/>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default PMap;
