import AOS from 'aos';
import 'aos/dist/aos.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faBars, faTimes, faCaretUp, 
         faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import "react-awesome-popover/dest/react-awesome-popover.css";


export default {
    init: () => {
        AOS.init();
        library.add(faChevronUp, faBars, faTimes, faCaretUp, faMapMarkedAlt);
    }
}
